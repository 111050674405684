import { useState, useEffect, useCallback } from 'react';
import { Tabs, Button, Card, Row, Col, Typography, notification, Select, Space, DatePicker, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import isInRole from '../../security/IsInRole';

import BoilerTable from '../boiler/BoilerTable.js';
import BoilerInsert from '../boiler/BoilerInsert.js';

import BranchTable from '../branch/BranchTable.js';
import BranchInsert from '../branch/BranchInsert.js';
import BoilerRoomTab from './tabs/BoilerRoomTab';

import CostPlanTable from '../cost_plan/CostPlanTable.js';
import CostPlanInsert from '../cost_plan/CostPlanInsert.js';
import InvoiceImport from '../invoice/InvoiceImport.js';

import DeliveryPointTable from '../delivery_point/DeliveryPointTable.js';
import DeliveryPointInsert from '../delivery_point/DeliveryPointInsert.js';

import BoilerRoomSensorTable from '../boiler_room_sensor/BoilerRoomSensorTable.js';
import BoilerRoomSensorInsert from '../boiler_room_sensor/BoilerRoomSensorInsert.js';

import ThermoSensorTable from '../thermo_sensor/ThermoSensorTable.js';
import ThermoSensorInsert from '../thermo_sensor/ThermoSensorInsert.js';

import { getWeather } from '../../util/WeatherUtil';

import { 
  PlusCircleOutlined, HomeOutlined
} from '@ant-design/icons';

import dayjs from 'dayjs';
import BoilerRoomTabPane from './tabs/BoilerRoomTabPane';

const BoilerRoom = () => {
    
    const { i18n, t } = useTranslation();

    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const [visibleBoilerForm, setVisibleBoilerForm] = useState(false);
    const [visibleForm, setVisibleForm] = useState(false);
    const [visibleCostPlanForm, setVisibleCostPlanForm] = useState(false);
    const [visibleDeliveryPointForm, setVisibleDeliveryPointForm] = useState(false);
    const [visibleInvoiceForm, setVisibleInvoiceForm] = useState(false);

    const [refreshBoilerForm, setRefreshBoilerForm] = useState(false);
    const [refreshForm, setRefreshForm] = useState(false);
    const [refreshCostPlanForm, setRefreshCostPlanForm] = useState(false);
    const [refreshDeliveryPointForm, setRefreshDeliveryPointForm] = useState(false);
    const [visibleBsForm, setVisibleBsForm] = useState(false);
    const [refreshBsForm, setRefreshBsForm] = useState(false);
    const [visibleTsForm, setVisibleTsForm] = useState(false);
    const [refreshTsForm, setRefreshTsForm] = useState(false);
    const [selectedKey, setSelectedKey] = useState();
    const [selectedParam, setSelectedParam] = useState();
    const [selectedYear, setSelectedYear] = useState(dayjs());

    const [boilerRoomListData, setBoilerRoomListData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [boilerRoomTabsData, setBoilerRoomTabsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBoilerRoomsLoading, setIsBoilerRoomsLoading] = useState(false);
    const [isLoadingBoilerRoom, setIsLoadingBoilerRoom] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState('kWh');
    const [dateRange, setDateRange] = useState([dayjs().subtract(14,'d'), dayjs()]);
    const [groupBy, setGroupBy] = useState("day");

    const [costPlanTypeData, setCostPlanTypeData] = useState([{id:1,name:t("Zemný plyn")}]);
    const [supplierData, setSupplierData] = useState([{id:1,name:t("SSE - Stredoslovenská energetika, a.s.")}]);

    const changeUnitAction =
      <Select 
        onChange={setSelectedUnit} 
        defaultValue={{ value: 'column', label: 'kWh' }}
        style={{ width: isMobile ? '100%' : 80 }} 
        options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
      />;

    useEffect(() => {
      customFetch();
    }, [params.id]);

    useEffect(() => {
      const L = require("leaflet");

      delete L.Icon.Default.prototype._getIconUrl;

      L.Icon.Default.mergeOptions({
          iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
          iconUrl: require("leaflet/dist/images/marker-icon.png"),
          shadowUrl: require("leaflet/dist/images/marker-shadow.png")
      });
    }, []);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const customFetch = async () => {
      if (!keycloak.authenticated)
        navigate(process.env.REACT_APP_ENERPO_URL);

      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom/'+params.id)
      .then(response => {
          setBoilerRoomData(response.data);
          setBoilerRoomTabsData(response.data.boilerRoomTabs);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchCompanyBoilerRooms = async (companyId) => {

      setIsBoilerRoomsLoading(true);
      setBoilerRoomListData([]);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom?limit=50&search=companyId:'+companyId)
      .then(response => {

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          if (response.data.content[k].id !== boilerRoomData.id) {
            myJson.push({
              value: response.data.content[k].id,
              label: response.data.content[k].name
            });
          }
        }

        setBoilerRoomListData(myJson);
        setIsBoilerRoomsLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeBoilerRooms = (id) => {
      navigate(process.env.REACT_APP_ENERPO_URL + '/boilerRoom/' + id);
    };

    const openNotification = (title, message) => {
      notification.error({
        message: title,
        description: message
      });
    };

    const showBoilerInsertForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleBoilerForm(false);
          setRefreshBoilerForm(true);
      } else {
          setVisibleBoilerForm(value);
      }
    }, []);

    const showInsertForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleForm(false);
          setRefreshForm(true);
      } else {
          setVisibleForm(value);
      }
    }, []);

    const showInsertBsForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleBsForm(false);
          setRefreshBsForm(true);
      } else {
          setVisibleBsForm(value);
      }
    }, []);

    const showInsertTsForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleTsForm(false);
          setRefreshTsForm(true);
      } else {
          setVisibleTsForm(value);
      }
    }, []);

    const showCostPlanInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleCostPlanForm(false);
        setRefreshCostPlanForm(true);
      } else {
        setVisibleCostPlanForm(value);
      }
    }, []);

    const showDeliveryPointInsertForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleDeliveryPointForm(false);
        setRefreshDeliveryPointForm(true);
      } else {
        setVisibleDeliveryPointForm(value);
      }
    }, []);

    const showInvoiceImportForm = useCallback((value) => {
      if (value === 'refresh') {
        setVisibleInvoiceForm(false);
        //setRefreshInvoiceForm(true);
      } else {
        setVisibleInvoiceForm(value);
      }
    }, []);

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const updateBoilerRoomParam = async (data) => {
      await axiosClient.put(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoomParam', data)
      .then(response => {
          customFetch();
          setIsLoadingBoilerRoom(false);
      }).catch('error: ' + console.log);
    }

    const setBoilerRoomParam = async (value) => {
      
      if (isNumber(value)) {
        setIsLoadingBoilerRoom(true);
        selectedParam.value = value;
        selectedParam.boilerRoomId = boilerRoomData.id;
        selectedParam.modifiedBy = keycloak.idTokenParsed.preferred_username;
        selectedParam.modifiedOn = dayjs();

        updateBoilerRoomParam(selectedParam);
      } else {
        openNotification('Error', 'Nesprávna hodnota. Hodnota musí byť číslo.');
      }
    };

    const isNumber = value => {
      const regex = /^-?\d*(\.\d+)?$/;
      return value && regex.test(value);
    };

    const changeDateRange = useCallback((value) => {
      if (value) setDateRange(value);
    }, []);

    const changeGroupBy = useCallback((value) => {
      setGroupBy(value);
      value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
      : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
      : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
    }, []);

    function onDateChange(date) {
      setSelectedYear(date);
    }

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/"}><HomeOutlined /></Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}> {boilerRoomData.companyName}</Link>, },
            { title: boilerRoomData.name },
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>  
                      <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                        <Space style={{ paddingLeft: 2 }}>
                          <Title level={5}>
                            {t("page.boiler_room")+": " + boilerRoomData.name + " - " + boilerRoomData.companyName}
                          </Title>
                        </Space>
                      </Col>
                      <Col span={6} xs={24} xl={6} style={{display: 'flex' }}>
                        <Select
                          placeholder={t("page.boiler_room_change")}
                          optionFilterProp="children"
                          style={{width: '100%'}}
                          loading={isBoilerRoomsLoading}
                          onFocus={() => fetchCompanyBoilerRooms(boilerRoomData.companyId)}
                          options={boilerRoomListData}
                          onChange={changeBoilerRooms}
                        >
                        </Select>
                      </Col>
                  </Row>
{/*
                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    <Col span={8} xs={24} xl={8}>
<Card />
                    </Col>
                    <Col span={8} xs={24} xl={8}>
<Card />
                    </Col>
                    <Col span={8} xs={24} xl={8}>
<Card />
                    </Col>
                  </Row>
*/}
                  <Row gutter={[10,10]} style={{ paddingBottom: 5 }}>
                    <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                      <Card //title={<Title level={5} style={{ margin: 0}}>{t("page.weather")}</Title>}
                        size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        { getWeather(boilerRoomData.id, false) }
                      </Card>
                    </Col>
                    <Col span={16} xs={24} xl={16} style={{display: 'flex'}}>
                      <Card bordered={true} 
                        size='small'
                        loading={isLoading} 
                        style={{width: '100%'}}
                      >
                        <MapContainer center={[boilerRoomData.lat, boilerRoomData.lng]} zoom={12} scrollWheelZoom={false} style={{ height: 150 }}>
                            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                            />
                              <Marker position={[boilerRoomData.lat, boilerRoomData.lng]} />
                        </MapContainer>
                      </Card>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ marginTop: 5, marginBottom: 0 }}>

                    {isMobile ?
                      <Col span={24} style={{ marginBottom: 10 }}>
                        {changeUnitAction}
                      </Col>
                    : <></>}

                    <Col span={24}>
                      <Tabs 
                        type="card" 
                        onTabClick={() => changeTab()} 
                        activeKey={selectedKey} 
                        tabBarExtraContent={!isMobile ? changeUnitAction : <></>}
                      >
                        {boilerRoomTabsData.map((tab, index) => {
                          console.log(tab);
                          if (tab.tabType === 'tab') {
                            return (
                              <>
                                <Tabs type="card" tab={t(tab.label)} key={tab.tabKey}>
                                  <Card size='small' styles={{body : {paddingTop: 0}}}>
                                    <BoilerRoomTab
                                      boilerRoomTabId={tab.id}
                                      boilerRoomId={boilerRoomData.id} 
                                      unit={selectedUnit} 
                                      refresh={isLoadingBoilerRoom}
                                      //dateRange={dateRange}
                                      //changeDateRange={changeDateRange} 
                                      dataType={tab.dataType}
                                      dataPath={tab.dataPath}
                                    />
                                  </Card>
                                </Tabs>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <TabPane tab={t(tab.label)} key={tab.tabKey}>
                                  <BoilerRoomTabPane
                                    boilerRoomTabId={tab.id}
                                    boilerRoomId={boilerRoomData.id} 
                                    unit={selectedUnit} 
                                    refresh={isLoadingBoilerRoom}
                                    dateRange={dateRange}
                                    changeDateRange={changeDateRange}
                                    groupBy={groupBy}
                                    changeGroupBy={changeGroupBy}
                                  />
                                </TabPane>
                              </>
                            );
                          }
                        })}
                      </Tabs>
                    </Col>
                  </Row>
                </Card>
            </Col>
        </Row>

        {boilerRoomData.hasCostPlan === true ? 
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
            <Card size="small" title={<span>{t("page.cost_plan") + ": "} <DatePicker style={{ marginLeft: 5 }} value={selectedYear} onChange={onDateChange} picker="year" /></span>}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showCostPlanInsertForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }} />}>{t("page.cost_plan_create")}</Button> : <></>}
                loading={isLoading}
              >
                <CostPlanTable boilerRoomData={boilerRoomData} selectedYear={selectedYear.format('YYYY')} setRefresh={setRefreshCostPlanForm} refresh={refreshCostPlanForm} />
                <CostPlanInsert boilerRoomData={boilerRoomData} costPlanTypeData={costPlanTypeData} setVisible={showCostPlanInsertForm} visible={visibleCostPlanForm} />
              </Card>
          </Col>
        </Row>
        : <></> }

        {boilerRoomData.hasCostPlan === true ? 
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
            <Card size="small" title={t("page.invoices_delivery_points")+":"}
              loading={isLoading}
              extra={ isInRole(['enerpo_admin']) ? 
                <>
                  <Row>
                    <Col>
                      <Button type="text" onClick={showDeliveryPointInsertForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.create_delivery_point")}</Button>|
                    </Col>
                    <Col>
                      <Button type="text" onClick={showInvoiceImportForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.invoice_import")}</Button>
                    </Col>
                  </Row>
                </> : <></>}
            >
              <DeliveryPointTable boilerRoomData={boilerRoomData} setRefresh={setRefreshDeliveryPointForm} refresh={refreshDeliveryPointForm} />
              <DeliveryPointInsert boilerRoomData={boilerRoomData} supplierData={supplierData} setVisible={showDeliveryPointInsertForm} visible={visibleDeliveryPointForm} />
              <InvoiceImport supplierData={supplierData} boilerRoomData={boilerRoomData} setVisible={showInvoiceImportForm} visible={visibleInvoiceForm} />
            </Card>
          </Col>
        </Row>
        : <></> }

        {/*
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
              <Card size="small" title={t("page.sources")+":"}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showBoilerInsertForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.source_create")}</Button> : <></>}
                loading={isLoading}
              >
                <BoilerTable boilerRoomData={boilerRoomData} setRefresh={setRefreshBoilerForm} refresh={refreshBoilerForm} />
                <BoilerInsert boilerRoomData={boilerRoomData} setVisible={showBoilerInsertForm} visible={visibleBoilerForm} />
              </Card>
          </Col>
        </Row>
        */}

        {boilerRoomData.hasBranch === true ? 
          <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
            <Col span={24}>
                <Card size="small" title={t("page.branches")+":"}
                  extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.branch_create")}</Button> : <></>}
                  loading={isLoading}
                >
                    <BranchTable boilerRoomData={boilerRoomData} setRefresh={setRefreshForm} refresh={refreshForm} />
                    <BranchInsert boilerRoomData={boilerRoomData} setVisible={showInsertForm} visible={visibleForm} />
                </Card>
            </Col>
          </Row>
        : <></> }

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
              <Card size="small" title={t("page.sensors_consume")+":"}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertBsForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.sensor_consume_create")}</Button> : <></>}
                loading={isLoading}
              >
                  <BoilerRoomSensorTable boilerRoomData={boilerRoomData} setRefresh={setRefreshBsForm} refresh={refreshBsForm} />
                  <BoilerRoomSensorInsert boilerRoomData={boilerRoomData} setVisible={showInsertBsForm} visible={visibleBsForm} />
              </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
              <Card size="small" title={t("page.sensors_thermo")+":"}
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertTsForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }}/>}>{t("page.sensor_thermo_create")}</Button> : <></>}
                loading={isLoading}
              >
                  <ThermoSensorTable boilerRoomData={boilerRoomData} setRefresh={setRefreshTsForm} refresh={refreshTsForm} />
                  <ThermoSensorInsert boilerRoomData={boilerRoomData} setVisible={showInsertTsForm} visible={visibleTsForm} />
              </Card>
          </Col>
        </Row>
                
      </>
    );
};

export default BoilerRoom;