import ControlTab from "./control/tn_kratka/ControlTab";
import ImageTab from "./image/ImageTab";

const BoilerRoomTab = ({ boilerRoomTabId, boilerRoomId, unit, refresh, dataType, dataPath }) => {

  return (
    <>
      {dataType === 'control' ? 
        <ControlTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} />
      : dataType === 'image' ? 
        <ImageTab path={dataPath}/>
      : <></>
      }
    </>
  );
};

export default BoilerRoomTab;