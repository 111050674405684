import { useState, useEffect } from 'react';
import { Spin, Avatar, Typography } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import ChartCard from '../../../util/ChartCard';
import { BatteryEmpty } from "@phosphor-icons/react";
import dayjs from 'dayjs';

const BatteryDischargeRevenueTotalCard = ({ batteryId, refresh }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const { Text } = Typography;
  const [data, setData] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [batteryId, refresh]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/'+batteryId+'/dischargeRevenueTotal')
      .then(response => {
        setData(parseFloat(response.data).toFixed(2).toString());
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  return (
    <Spin spinning={isLoading}>
      <ChartCard
          bordered={false}
          avatar={<Avatar style={{ backgroundColor: '#fff', borderColor: '#1677ff', color: '#1677ff' }} shape="square" size={55} icon={<BatteryEmpty />} />}
          title={<Text style={{ fontSize: 12 }}>{t("page.revenue") + " " + dayjs().format("YYYY")}</Text>}
          loading={isLoading}
          total={<Text style={{ fontSize: 16, fontWeight: 600 }}>{data.toLocaleString('en-US') + ' Eur'}</Text>}
      />
    </Spin>
  );
};

export default BatteryDischargeRevenueTotalCard;