import { useState, useEffect, useCallback } from 'react';
import { Tabs, Card, Row, Col, Typography, Select, Space, Descriptions, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import { getWeather } from '../../util/WeatherUtil';

import FweActualProductionCard from './chartCards/FweActualProductionCard';
import FweActualRevenueCard from './chartCards/FweActualRevenueCard';
import FweTotalProductionCard from './chartCards/FweTotalProductionCard';
import FweTotalRevenueCard from './chartCards/FweTotalRevenueCard';

import FweActualPowerChart from './charts/FweActualPowerChart';
import FweProductionChart from './charts/FweProductionChart';
import FweRevenueChart from './charts/FweRevenueChart';
import FwePowerChart from './charts/FwePowerChart';

import { 
  CheckCircleTwoTone, CloseCircleTwoTone, HomeOutlined
} from '@ant-design/icons';

import dayjs from 'dayjs';

const Fwe = () => {
    
    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

    const [fweData, setFweData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [fweListData, setFweListData] = useState([]);
    const [fweStatus, setFweStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFwesLoading, setIsFwesLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

    const [groupBy, setGroupBy] = useState("hour");
    const [selectedKey, setSelectedKey] = useState();
    const [selectedUnit, setSelectedUnit] = useState('kWh');
    
    const changeUnitAction =
      <Select 
        onChange={setSelectedUnit} 
        defaultValue={{ value: 'column', label: 'kWh' }}
        style={{ width: isMobile ? '100%' : 80 }} 
        options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
      />;

    useEffect(() => {
      customFetch();
      fetchFweStatus();
    }, [params.id]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+params.id)
      .then(response => {
          setFweData(response.data);
          setBoilerRoomData(response.data.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchFweStatus = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+params.id+'/status')
      .then(response => {
          setFweStatus(response.data);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchCompanyFwes = async (companyId) => {

      setIsFwesLoading(true);
      setFweListData([]);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe?limit=20&search=boilerRoomcompanyId:'+companyId)
      .then(response => {

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          if (response.data.content[k].id !== fweData.id) {
            myJson.push({
              value: response.data.content[k].id,
              label: response.data.content[k].name
            });
          }
        }

        setFweListData(myJson);
        setIsFwesLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeFwes = (id) => {
      navigate(process.env.REACT_APP_ENERPO_URL + '/fwe/' + id);
    };

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const changeDateRange = useCallback((value) => {
      if (value) setDateRange(value);
    }, []);

    const changeGroupBy = useCallback((value) => {
      setGroupBy(value);
      value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
      : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
      : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
    }, []);

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/"}><HomeOutlined /></Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}> {boilerRoomData.companyName}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}>{boilerRoomData.name}</Link>, },
            { title: t("page.fve") },
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                  
                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>  
                      <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                        <Space style={{ paddingLeft: 2 }}>
                          <Title level={5}>
                            {t("page.fve") + ": " + fweData.name + " - " + boilerRoomData.companyName}
                          </Title>
                        </Space>
                      </Col>
                      <Col span={6} xs={24} xl={6} style={{display: 'flex' }}>
                        <Select
                          placeholder={t("page.fwe_change")}
                          optionFilterProp="children"
                          style={{width: '100%'}}
                          loading={isFwesLoading}
                          onFocus={() => fetchCompanyFwes(boilerRoomData.companyId)}
                          options={fweListData}
                          onChange={changeFwes}
                        >
                        </Select>
                      </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    <Col span={6} xs={24} xl={6}>
                      <FweActualProductionCard fweId={fweData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <FweActualRevenueCard fweId={fweData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <FweTotalProductionCard fweId={fweData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <FweTotalRevenueCard fweId={fweData.id} />
                    </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    {boilerRoomData.hasThermoSensor ? 
                      <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                        <Card size='small'
                          style={{width: '100%'}}
                          bordered={true} 
                          loading={isLoading}
                        >
                          { getWeather(boilerRoomData.id, false) }
                        </Card>
                      </Col>
                    : <></> }
                    <Col span={8} xs={24} xl={boilerRoomData.hasThermoSensor ? 8 : 16} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <Descriptions column={1} bordered={true} size="small" labelStyle={{ paddingTop: 7, paddingBottom: 7 }}>
                          <Descriptions.Item contentStyle={{ paddingTop: 5, paddingBottom: 5 }} label={t("page.address")+":"}>{boilerRoomData.address}</Descriptions.Item>
                          <Descriptions.Item contentStyle={{ paddingTop: 5, paddingBottom: 5 }} label={t("page.installed_power")+":"}>{fweData.inputPower} kW</Descriptions.Item>
                          <Descriptions.Item contentStyle={{ paddingTop: 5, paddingBottom: 5 }} label={t("page.status")+":"}>
                            {fweStatus ?
                              <>{t("page.running")} <CheckCircleTwoTone twoToneColor="#52c41a" /></>
                            :
                              <>{t("page.down")} <CloseCircleTwoTone twoToneColor="#FF0000" /></>
                            }
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                    <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                          <FweActualPowerChart fweId={fweData.id} maxPower={fweData.inputPower} />
                      </Card>
                    </Col>
                  </Row>

                  <Row gutter={[10,10]}>

                    {isMobile ?
                      <Col span={24} style={{ marginBottom: 10 }}>
                        {changeUnitAction}
                      </Col>
                    : <></>}
                    
                    <Col span={24}>
                      <Tabs 
                        type="card" 
                        onTabClick={() => changeTab()} 
                        activeKey={selectedKey} 
                        destroyInactiveTabPane={true}
                        tabBarExtraContent={!isMobile ? changeUnitAction : <></>}
                      >
                        <Tabs type="card" tab={t("page.production")} key="fweProductionTab">
                          <FweProductionChart fweId={fweData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Tabs>
                        <Tabs type="card" tab={t("page.revenue")} key="fweRevenueTab">
                          <FweRevenueChart fweId={fweData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Tabs>
                        <Tabs type="card" tab={t("page.power")} key="fwePowerTab">
                          <FwePowerChart fweId={fweData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Tabs>
                      </Tabs>
                    </Col>
                  </Row>
                </Card>
            </Col>
        </Row>
      </>
    );
};

export default Fwe;